// eslint-disable-next-line import/no-anonymous-default-export
export default axios => ({
    getTree({ id, search }) {
        return axios.get(`/projects/${id}/tree`, {
            params: {
                search: search,
            }
        });
    },
    export({ id }) {
        return axios.get(`/classes/${id}/export`);
    },
    index() {
        return axios.get(`/projects`);
    },
    delete(id) {
        return axios.delete(`/projects/${id}`);
    }
});
