import './BaseTableComponent.scss';

const BaseTableComponent = (props) => {

    const handlerChangeRow = (e) => {
        props?.onChangeRow && props.onChangeRow(e);
    };

    const handlerChangeTd = (e) => {
        props?.onChangeTd && props.onChangeTd(e);
    };

    return (
        <div className="table-responsive">
            <h3>{ props.title }</h3>
            <table>
                <thead>
                    <tr>
                        {props.headers && props.headers.map((el, ind) => (<td key={`table-header-${ind}`}>{el.name}</td>))}
                    </tr>
                </thead>
                <tbody>
                    {
                        props.body && props.body?.map((el) => (
                            <tr key={`table-row-${el.id}`}
                                onClick={() => handlerChangeRow(el)}>
                                {
                                    Object.keys(el).map((k, i) => (
                                        <td
                                            className={`column-${k}`}
                                            key={`table-row-${el.id}-collumn-${i}`}
                                            onClick={() => handlerChangeTd({name: k, value: el[k], row: el})}
                                            dangerouslySetInnerHTML={{ __html: el[k] }}
                                        />
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
};

export default BaseTableComponent;
