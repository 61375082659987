import ThreeListItemComponent from './ThreeListItemComponent';
import './ThreeListComponent.scss';

const ThreeListComponent = ({ items }) => {
    return (
        <div className='three-list-wrapper'>
            { items.map(el => (<ThreeListItemComponent 
                key={`collapse-${el.id}`} 
                item={el} 
            />)) }
        </div>
    )
}

export default ThreeListComponent;
