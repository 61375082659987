import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { onSelectClass } from '../../Redux/Actions';
import debounce from 'lodash.debounce';
import cx from 'classnames';
import { useNavigate } from "react-router-dom";

import FindReplace from '../../Utils/FindReplace';
import { repos } from '../../Services/ApiService/ApiService';
import './SearchTableComponent.scss';

const SearchTableComponent = ({ updateThree, id }) => {
    const [userQuery, setUserQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [items, setItems] = useState([]);
    const navigate = useNavigate();

    const classId = useSelector(state => {
        const { onSelectClass } = state;
        return onSelectClass.classId;
    });

    const dispatch = useDispatch();

    const getItems = async (search) => {
        setLoading(true);
        try {
            // await new Promise((resolve, reject) => {
            //     setTimeout(() => resolve({}), 500);
            // });

            const { data: { data } } = await repos.projects.getTree({ id: id, search: search });
            setItems(data.items);
            const tree = FindReplace(search, data.tree, 'name');
            updateThree(tree);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const updateQuery = () => {
        getItems(userQuery)
    };

    const exportProject = async () => {
        setLoadingExport(true);
        try {
            const { data } = await repos.projects.export({ id: classId });
            const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
                JSON.stringify(data)
            )}`;
            const link = document.createElement("a");
            link.href = jsonString;
            link.download = `export-id-${classId}.json`;

            link.click();
            console.log(data)
        } catch (e) {
            console.error(e);
        } finally {
            setLoadingExport(false);
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedQuery = useCallback(debounce(updateQuery, 500), [userQuery]);

    const onSubmitHandler = (e) => {
        e.preventDefault();
        delayedQuery();
    };

    const onChange = (id) => {
        dispatch(onSelectClass(id));
    }

    const backToProject = () => (navigate('/projects'));

    const location = useLocation();

    useEffect(() => {
        dispatch(onSelectClass(null));
    }, [location.pathname]);

    return (
        <div className='search-table'>
            <div className='search-table__box'>
                <h3>Поиск класса</h3>

                <form onSubmit={onSubmitHandler}>
                    <input onChange={(e) => setUserQuery(e.target?.value)} type="search" name="query" placeholder="Введите часть названия..." />
                    <button type="submit">Поиск</button>
                    {loading ? <span>Загрузка...</span> : ''}
                </form>

                {
                    items?.length
                        ?
                        <>
                            <h3>Результаты поиска</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <td>ID класса</td>
                                        <td>Имя класса</td>
                                        <td>Описание класса</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        items?.map(el => (
                                            <tr key={`table-row-${el.id}`}
                                                onClick={() => onChange(el.id)}
                                                className={cx({
                                                    active: el.id === classId
                                                })}>
                                                <td>{el.id}</td>
                                                <td>{el.name}</td>
                                                <td>{el.description}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </>
                        :
                        <div className='search-empty'>{`Пока ничего не найдено :(`}</div>
                }
            </div>
            <div className='search-table__box'>
                <div className='search-footer'>
                    <button onClick={() => backToProject()}>Назад</button>
                    <button onClick={() => exportProject()} disabled={!classId || loadingExport}>Экспорт</button>
                </div>
            </div>
        </div>
    )
}

export default SearchTableComponent;
