const replaceWord = (str,index,tag) => {
    const strLen = str.length;
    return `${str.substr(0,index)}<${tag}>${str.substr(index, strLen-1)}</${tag}>${str.substr(index+strLen-1)}`;
}

const findReplace = (query, items, fieldName) => {
    return items.map(el => {
        const stringIndex = el[fieldName].toLowerCase().indexOf(query.toLowerCase());
        return {
            ...el,
            [fieldName]: stringIndex >= 0 ? replaceWord(el[fieldName], stringIndex, 'strong') : el[fieldName],
            children: el?.children ? findReplace(query, el.children, fieldName) : [],
        }
    });
}

export default findReplace;