import { SELECT_CLASS } from "./Types"

const intialState = {
  classId: null
}

export const onSelectClass = (state = intialState, action) => {
  switch(action.type) {

    case SELECT_CLASS:
      return {
        ...state,
        classId: action.classId
      }

    default:
      return state;
  }
}
