import axios from 'axios';
import apiProjects from './Modules/Projects';

const token = '1|q8E8l3RJuVx2Z9K2JkLygrL62BMZ38iZb0H44RUx';
const client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

client.defaults.headers.common = {'Authorization': `Bearer ${token}`}

export const repos = {
    projects: apiProjects(client),
};
