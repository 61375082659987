import { createBrowserRouter } from "react-router-dom";
import HomeScreen from './Screens/HomeScreen';
import ProjectsScreen from './Screens/ProjectsScreen';
import ProjectScreen from './Screens/ProjectsScreen/_id';

export default createBrowserRouter([
    {
        path: "/",
        element: <HomeScreen />,
    },
    {
        path: "/projects",
        element: <ProjectsScreen />,
    },
    {
        path: "/projects/:id",
        element: <ProjectScreen />,
    },
]);
