import './FormSettingsComponent.scss';

const FormSettingsComponent = () => {
    return (
        <div className='form-settings'>
            <h3>Свойства</h3>
        </div>
    );
};

export default FormSettingsComponent;
