import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onSelectClass } from '../../Redux/Actions';
import { Collapse } from 'react-collapse';
import cx from 'classnames';
import ThreeListComponent from './ThreeListConponent';

const ThreeListItemComponent = ({ item }) => {
    const [isButtonCollapseOpen, setIsButtonCollapseOpen] = useState(true);

    const classId = useSelector(state => {
        const { onSelectClass } = state;
        return onSelectClass.classId;
    });

    const dispatch = useDispatch();

    const handlerClick = (item) => {
        if (item.children?.length) {
            setIsButtonCollapseOpen(!isButtonCollapseOpen)
        }
        if (!item.sort) {
            dispatch(onSelectClass(item.id));
        }
    }

    return (
        <div className='three-list'>
            {item.children?.length 
                ? 
                <>
                    <div 
                        className={cx('three-list__control three-list__control--collapse', {
                            'three-list__control--collabsed': isButtonCollapseOpen,
                            active: item.id === classId && !item.sort
                        })}
                        aria-controls={item.id}
                        aria-expanded={isButtonCollapseOpen}
                        onClick={() => handlerClick(item)}
                        dangerouslySetInnerHTML={{ __html: `ID:${item.id} ${item.name}` }}
                        />
                    <Collapse isOpened={isButtonCollapseOpen} theme={{ collapse: 'three-list', content: 'three-list__content' }}>
                        <ThreeListComponent 
                            items={item.children} 
                        />
                    </Collapse>
                </>
                :
                <div className={cx('three-list__control', {
                    active: item.id === classId && !item.sort
                })}
                onClick={() => handlerClick(item)}
                dangerouslySetInnerHTML={{ __html: `ID:${item.id} ${item.name}` }}
                />
            }
        </div>
    )
}

export default ThreeListItemComponent;
