import React from 'react';
import { Link } from 'react-router-dom'

import DefaultLayout from '../../Layouts/DefaultLayout/DefaultLayout';
import './styles.scss';

const HomeScreen = (props) => {
    return (
        <DefaultLayout {...props} renderContent={
            <div className='home-screen'>
                <Link to="/projects">Все проекты</Link>
            </div>
        } />
    );
};

export default HomeScreen;