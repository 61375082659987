import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import DefaultLayout from '../../Layouts/DefaultLayout/DefaultLayout';
import ThreeListConponent from '../../Components/ThreeListConponent/ThreeListConponent';
import SearchTableComponent from '../../Components/SearchTableComponent/SearchTableComponent';
import FormSettingsComponent from '../../Components/FormSettingsComponent/FormSettingsComponent';
import './styles.scss';

const ProjectScreen = (props) => {
    const [items, setItems] = useState([]);
    const { id } = useParams();
    return (
        <DefaultLayout {...props} renderContent={
            <div className='project-screen'>
                <div className='project-screen__item project-screen__item--small project-screen__item--left'>
                    <ThreeListConponent items={items}/>
                </div>
                <div className='project-screen__item project-screen__item--center'>
                    <SearchTableComponent id={id} updateThree={(items) => setItems(items)}/>
                </div>
                <div className='project-screen__item project-screen__item--small project-screen__item--right'>
                    <FormSettingsComponent />
                </div>
            </div>
        } />
    );
};

export default ProjectScreen;