import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import DefaultLayout from '../../Layouts/DefaultLayout/DefaultLayout';
import BaseTableComponent from '../../Components/BaseTableComponent/BaseTableComponent';
import { repos } from '../../Services/ApiService/ApiService';

import './styles.scss';

const ProjectsScreen = (props) => {
    const [loading, setLoading] = useState(false);
    const [table, setTable] = useState([]);
    const navigate = useNavigate();

    const getItems = async () => {
        setLoading(true);
        try {
            const { data: { data } } = await repos.projects.index();
            setTable({
                title: 'Выбор проекта',
                headers: [
                    { name: 'id' },
                    { name: 'Имя проекта' },
                    { name: 'Описание проекта' },
                    { name: 'Действия' },
                ],
                body: data.map(el => ({ id: el.id, name: el.name, description: el.description, delete: '&times' }))
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };
    const deleteItem = async (id) => {
        setLoading(true);
        try {
            await repos.projects.delete(id);
            getItems();
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const handlerTd = (e) => {
        switch (e.name) {
            case 'name':
                navigate(`/projects/${e.row.id}`);
                break;
            case 'delete':
                let isDelete = window.confirm("Вы уверены что хотите удалить проект?");
                if (isDelete) {
                    // alert('Deleted');
                    deleteItem(e.row.id);
                }
                break;
        
            default:
                break;
        }
    }

    useEffect(() => {
        getItems();
    }, []);

    return (
        <DefaultLayout {...props} renderContent={
            <div className='projects-screen'>
                <div className='projects-screen__item'>
                    { loading ? (<h3>Загрузка...</h3>) : <BaseTableComponent onChangeTd={(e) => handlerTd(e)} {...table}/> }
                </div>
            </div>
        }/>
    );
};

export default ProjectsScreen;